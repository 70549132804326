<template>
  <div>
    <van-tabbar v-model="active" @change="onChange">
      <!-- <van-tabbar-item badge="3">
        <span>测试</span>
        <template #icon="props">
          <img :src="props.active ? icon.active : icon.inactive" />
        </template>
      </van-tabbar-item> -->
      <van-tabbar-item>
        <span>首页</span>
        <template #icon="props">
          <img
            :src="
              props.active
                ? `${publickPath}static/img/icons/main_active.png`
                : `${publickPath}static/img/icons/main.png`
            "
          />
        </template>
      </van-tabbar-item>
      <van-tabbar-item>
        <span>我的</span>
        <template #icon="props">
          <img
            :src="
              props.active
                ? `${publickPath}static/img/icons/my_active.png`
                : `${publickPath}static/img/icons/my.png`
            "
          />
        </template>
      </van-tabbar-item>
    </van-tabbar>

    <!-- <router-link to="/">Home</router-link> |
    <router-link to="/main">Main</router-link> -->
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
    <!-- <router-view></router-view> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      publickPath: process.env.BASE_URL,
      // active: this.$route.path == '/test'?0:this.$route.path == '/main'?1:this.$route.path == '/my'?2:0,
      active:
        this.$route.path == "/main" ? 0 : this.$route.path == "/my" ? 1 : 0,
      icon: {
        active: "https://img.yzcdn.cn/vant/user-active.png",
        inactive: "https://img.yzcdn.cn/vant/user-inactive.png",
      },
    };
  },
  created() {
    // console.log(this.$route)
  },
  watch: {
    active(newValue, oldValue) {
      console.log('active', newValue);
    },
    $route: {
      handler(val, oldval) {
        if (val.path == "/main") {
          this.active = 0;
        } else if (val.path == "/my") {
          this.active = 1;
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
  methods: {
    onChange(index) {
      console.log(index);
      switch (index) {
        // case 0:
        //   this.$router.push('/test')
        //   break;
        case 0:
          this.$router.push("/main");
          break;
        case 1:
          this.$router.push("/my");
          break;

        default:
          break;
      }

      // Notify({ type: 'primary', message: index });
    },
  },
};
</script>
